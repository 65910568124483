/* You can add global styles to this file, and also import other style files */
@use "@angular/cdk/overlay-prebuilt";
@use "definitions" as *;

body {
    min-width: 1500px;
    overflow-x: auto;
}

.body__content {
    font-size: 16px;
    line-height: 24px;
}

.table__column--header-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lg-tooltip.lg-tooltip--simple {
    a {
        color: lg-color(--base-30);

        &:visited {
            color: lg-color(--base-30);
        }

        &:hover {
            color: lg-color(--base-40);
        }

        &:active {
            color: lg-color(--base-50);
        }
    }
}
